import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import Container from "../../components/Container";

import { ReactComponent as OrderHistoryIcon } from "../../assets/images/order_history.svg";
import { ReactComponent as SalesIcon } from "../../assets/images/sales.svg";
import { ReactComponent as AccountDetailIcon } from "../../assets/images/account_detail.svg";
import { ReactComponent as EventsIcon } from "../../assets/images/events1.svg";
import { ReactComponent as ManageMenuIcon } from "../../assets/images/manage_menu.svg";
import { ReactComponent as RestInfoIcon } from "../../assets/images/rest_info.svg";
import { ReactComponent as SubscriptionIcon } from "../../assets/images/subscription.svg";
import { ReactComponent as RestSettingIcon } from "../../assets/images/rest_setting.svg";

import { BTN_COLOR, BTN_TEXT_COLOR } from "../../constants";

let ICON_SIZE = 25;

const Settings = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").pop()
  );

  let settings_list = [
    {
      page: "manage_menu ",
      label: "Manage Menu ",
      href: "/restaurant/" + restaurantId + "/manage_menu",
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "ar_models",
      label: "AR Models",
      href: "/restaurant/" + restaurantId + "/ar_models",
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "gift_cards",
      label: "Gift Cards",
      href: "/restaurant/" + restaurantId + "/gift_card",
      icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "order_history",
      label: "Order History",
      href: "/restaurant/" + restaurantId + "/order_history",
      icon: <OrderHistoryIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "sales",
      label: "Sales",
      href: "/restaurant/" + restaurantId,
      icon: <SalesIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "account_details",
      label: "Account Details",
      href: "/restaurant/" + restaurantId + "/account_detail",
      icon: <AccountDetailIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "events",
      label: "Events ",
      href: "/restaurant/" + restaurantId + "/events",
      icon: <EventsIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "Subscriptions",
      label: "Subscriptions ",
      href: "/restaurant/" + restaurantId + "/subscriptions",
      icon: <SubscriptionIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "restaurant_info",
      label: "Restaurant Info ",
      href: "/restaurant/" + restaurantId + "/info",
      icon: <RestInfoIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "restaurant_info",
      label: "Portal Settings",
      href: "/restaurant/" + restaurantId + "/custom_info",
      icon: <RestInfoIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "table_setup_settings",
      label: "Table Setup Settings",
      href: "/restaurant/" + restaurantId + "/table_setup_settings",
      icon: <RestInfoIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "stripe_settings",
      label: "Stripe Settings",
      href: "/restaurant/" + restaurantId + "/stripe_settings",
      icon: <RestInfoIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
    {
      page: "takeaway_settings",
      label: "Take Away Settings",
      href: "/restaurant/" + restaurantId + "/takeaway_settings",
      icon: <RestSettingIcon width={ICON_SIZE} height={ICON_SIZE} />,
    },
  ];

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      <Grid container item xs={12} p={3} sx={styles.settingsMainDiv}>
        <Grid container item xs={12} md={10} sx={styles.settingsBtnMainDiv}>
          {settings_list.map((x, i) => {
            return (
              <Grid key={i} item xs={6} md={3}>
                <Box
                  sx={styles.settingsBtnDiv}
                  onClick={() => navigate(x.href)}
                >
                  <Box>{x.icon}</Box>
                  <Typography sx={styles.settingsBtnDivText}>
                    {x.label}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;

const styles = {
  settingsMainDiv: {
    backgroundColor: "#FFF",
    borderRadius: "8px",
  },
  settingsBtnMainDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  settingsBtnDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: BTN_COLOR,
    borderRadius: "8px",
    cursor: "pointer",
    width: { xs: "98%", md: "90%" },
    height: 100,
    px: 1.5,
    py: 1.3,
    mb: 3,
  },
  settingsBtnDivText: {
    color: BTN_TEXT_COLOR,
    fontSize: 12.5,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
};
