import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Typography from "@mui/material/Typography";

import "./../assets/css/custom.css";

import styles from "./FormTimePicker.module.css";

import { THEME_COLOR } from "../constants";

const TimePickerSlotProps = {
  actionBar: {
    sx: {
      "& .MuiButton-root": {
        color: "#000",
        fontFamily: "GilroySemiBold",
      },
    },
  },
  digitalClockSectionItem: {
    sx: {
      fontSize: 13,
      fontFamily: "GilroySemiBold",
      "&.Mui-selected": {
        backgroundColor: `${THEME_COLOR}`,
        color: "#000",
        "&:hover": {
          backgroundColor: `${THEME_COLOR}`,
          color: "#000",
        },
      },
    },
  },
  textField: {
    variant: "standard",
    InputLabelProps: {
      style: { fontFamily: "GilroyRegular", color: "#000" },
      shrink: true,
    },
    inputProps: {
      readOnly: true,
      sx: {
        height: 30,
        fontSize: 13,
        fontFamily: "GilroySemiBold",
      },
    },
    sx: {
      "& .MuiInputBase-root": {
        borderBottom: "1px solid #0000006b",
        "&:before, :after, :hover:not(.Mui-disabled):before": {
          borderBottom: "1px solid #0000006b",
        },
        "&.Mui-error:after": {
          borderBottom: "1px solid #0000006b",
        },
      },
      svg: { color: "#000", fontSize: 18 },
      "& .MuiButtonBase-root": {
        width: 35,
        height: 35,
      },
    },
  },
};

const FormTimePickers = (props) => {
  const {
    value = "",
    label = "",
    format = "hh:mm aa",
    clearable = false,
    onChange = () => null,
    error = "",
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label={label}
        value={value}
        format={format}
        onChange={onChange}
        slotProps={{
          ...TimePickerSlotProps,
          textField: {
            ...TimePickerSlotProps?.textField,
            ...props?.textFieldSlotProps,
          },
          field: { clearable: clearable },
        }}
      />
      {error ? (
        <Typography className={styles?.["error-text"]}>{error}</Typography>
      ) : null}
    </LocalizationProvider>
  );
};

export default FormTimePickers;
