import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./AddSubMenuBtn.module.css";

const AddSubMenuBtn = (props) => {
  const { count, onAddSub = (x) => x } = props;

  return (
    <Box className={styles?.["main-view"]}>
      <Box className={styles?.["add-sub-view"]} onClick={() => onAddSub("-")}>
        <Typography className={styles?.["add-sub-text"]}>-</Typography>
      </Box>
      <Box className={styles?.["count-view"]}>
        <Typography className={styles?.["count-text"]}>{count}</Typography>
      </Box>
      <Box className={styles?.["add-sub-view"]} onClick={() => onAddSub("+")}>
        <Typography className={styles?.["add-sub-text"]}>+</Typography>
      </Box>
    </Box>
  );
};

export default AddSubMenuBtn;
