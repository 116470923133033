import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import styles from "./TakeAwaySettings.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import RestaurantHeader from "../../components/RestaurantHeader";
import SwitchInput from "../../components/SwitchInput";
import SettingTextField from "../../components/SettingTextField";
import FormTimePickers from "../../components/FormTimePicker";
import AddSubMenuBtn from "../../components/AddSubMenuBtn";

import { ReactComponent as InfoIcon } from "../../assets/images/rest_info.svg";

const TakeAwaySettings = () => {
  const navigate = useNavigate();
  const { rest_id } = useParams();

  const [restaurantId, setRestaurantId] = useState(rest_id);
  const [loading, setLoading] = useState(false);
  const [restaurantDetail, setRestaurantDetail] = useState(null);
  const [onlineOrderVal, setOnlineOrderVal] = useState(false);
  const [deliveryWindowStartTime, setDeliveryWindowStartTime] = useState("");
  const [deliveryWindowEndTime, setDeliveryWindowEndTime] = useState("");
  const [takeAwayWindowStartTime, setTakeAwayWindowStartTime] = useState("");
  const [takeAwayWindowEndTime, setTakeAwayWindowEndTime] = useState("");
  const [minCollectionOrderAmount, setMinCollectionOrderAmount] = useState("");
  const [minDeliveryOrderAmount, setMinDeliveryOrderAmount] = useState("");
  const [isDeliveryEnabledVal, setIsDeliveryEnabledVal] = useState(false);
  const [isTakeAwayEnabledVal, setIsTakeAwayEnabledVal] = useState(false);
  const [busyModeVal, setBusyModeVal] = useState(false);
  const [busyModeTimeVal, setBusyModeTimeVal] = useState(0);
  const [defaultBusyModeTimeVal, setDefaultBusyModeTimeVal] = useState("");
  const [deliveryRadiusVal, setDeliveryRadiusVal] = useState("");
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [error, setError] = useState(null);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  useEffect(() => {
    getRestaurantData();
  }, []);

  useEffect(() => {
    let {
      onlineOrder = false,
      busyMode = false,
      busyModeTime = 0,
      defaultBusyModeTime = "",
      minimumCollectionOrderAmount = "",
      minimumDeliveryOrderAmount = "",
      deliveryRadius = "",
      deliveryWindow = [],
      takeAwayWindow = [],
      isDeliveryEnabled = false,
      isTakeAwayEnabled = false,
    } = restaurantDetail || {};

    const deliveryWindowData = onSetDeliveryTakeAwayWindow(
      deliveryWindowStartTime,
      deliveryWindowEndTime
    );
    const takeAwayWindowData = onSetDeliveryTakeAwayWindow(
      takeAwayWindowStartTime,
      takeAwayWindowEndTime
    );

    const data = {
      onlineOrder: onlineOrderVal,
      isDeliveryEnabled: isDeliveryEnabledVal,
      isTakeAwayEnabled: isTakeAwayEnabledVal,
      busyMode: busyModeVal,
      busyModeTime: Number(busyModeTimeVal),
      defaultBusyModeTime: Number(defaultBusyModeTimeVal),
      minimumCollectionOrderAmount: Number(minCollectionOrderAmount),
      minimumDeliveryOrderAmount: Number(minDeliveryOrderAmount),
      deliveryRadius: Number(deliveryRadiusVal),
      deliveryWindow: deliveryWindowData,
      takeAwayWindow: takeAwayWindowData,
    };

    if (deliveryWindow.length) {
      delete deliveryWindow?.[0]?._id;
    }

    if (takeAwayWindow.length) {
      delete takeAwayWindow?.[0]?._id;
    }

    const restData = {
      onlineOrder,
      isDeliveryEnabled,
      isTakeAwayEnabled,
      busyMode,
      busyModeTime,
      defaultBusyModeTime,
      minimumCollectionOrderAmount,
      minimumDeliveryOrderAmount,
      deliveryRadius,
      deliveryWindow,
      takeAwayWindow,
    };

    if (JSON.stringify(data) !== JSON.stringify(restData)) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [
    defaultBusyModeTimeVal,
    minDeliveryOrderAmount,
    minCollectionOrderAmount,
    deliveryWindowEndTime,
    deliveryWindowStartTime,
    takeAwayWindowEndTime,
    takeAwayWindowStartTime,
    isDeliveryEnabledVal,
    isTakeAwayEnabledVal,
    onlineOrderVal,
    busyModeVal,
    busyModeTimeVal,
    deliveryRadiusVal,
  ]);

  const getRestaurantData = async () => {
    setLoading(true);
    const response = await Api.getRestaurantData(restaurantId);
    if (response.success) {
      const data = response.data;
      onUpdateSetVariables(data);
      setRestaurantDetail(data);
    } else {
      setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    }
    setLoading(false);
  };

  const onSaveGeneralSettings = () => {
    let err = null;

    if (minDeliveryOrderAmount && /[^0-9.]/g.test(minDeliveryOrderAmount)) {
      err = {
        ...err,
        minimumDeliveryOrderAmount: "Invalid Minimum Delivery Order Amount",
      };
    }

    if (deliveryWindowStartTime && !deliveryWindowEndTime) {
      err = {
        ...err,
        deliveryWindowEndTime: "Delivery window end time is required",
      };
    }

    if (deliveryWindowEndTime && !deliveryWindowStartTime) {
      err = {
        ...err,
        deliveryWindowStartTime: "Delivery window start time is required",
      };
    }

    if (takeAwayWindowStartTime && !takeAwayWindowEndTime) {
      err = {
        ...err,
        takeAwayWindowEndTime: "Take Away window end time is required",
      };
    }

    if (takeAwayWindowEndTime && !takeAwayWindowStartTime) {
      err = {
        ...err,
        takeAwayWindowStartTime: "Take Away window start time is required",
      };
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      defaultBusyModeTime: defaultBusyModeTimeVal ? defaultBusyModeTimeVal : 0,
      minimumCollectionOrderAmount: minCollectionOrderAmount
        ? minCollectionOrderAmount
        : 0,
      minimumDeliveryOrderAmount: minDeliveryOrderAmount
        ? minDeliveryOrderAmount
        : 0,
      onlineOrder: onlineOrderVal,
      busyMode: busyModeVal,
      busyModeTime: busyModeTimeVal ? busyModeTimeVal : 0,
      deliveryRadius: deliveryRadiusVal ? deliveryRadiusVal : 0,
      isDeliveryEnabled: isDeliveryEnabledVal,
      isTakeAwayEnabled: isTakeAwayEnabledVal,
      deliveryWindow: onSetDeliveryTakeAwayWindow(
        deliveryWindowStartTime,
        deliveryWindowEndTime
      ),
      takeAwayWindow: onSetDeliveryTakeAwayWindow(
        takeAwayWindowStartTime,
        takeAwayWindowEndTime
      ),
    };
    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, restaurantId).then((response) => {
      if (response.success) {
        setRestaurantDetail({
          ...restaurantDetail,
          ...data,
        });
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onUpdateSetVariables = (data) => {
    const {
      onlineOrder = false,
      busyMode = false,
      busyModeTime = 0,
      defaultBusyModeTime = "",
      minimumCollectionOrderAmount = "",
      minimumDeliveryOrderAmount = "",
      deliveryRadius = "",
      deliveryWindow = [],
      takeAwayWindow = [],
      isDeliveryEnabled = false,
      isTakeAwayEnabled = false,
    } = data || {};

    setOnlineOrderVal(onlineOrder);
    setIsDeliveryEnabledVal(isDeliveryEnabled);
    setIsTakeAwayEnabledVal(isTakeAwayEnabled);
    setBusyModeVal(busyMode);
    setMinCollectionOrderAmount(minimumCollectionOrderAmount);
    setMinDeliveryOrderAmount(minimumDeliveryOrderAmount);
    setBusyModeTimeVal(busyModeTime);
    setDefaultBusyModeTimeVal(defaultBusyModeTime);
    setDeliveryRadiusVal(deliveryRadius);
    setDeliveryWindowStartTime(
      onSetTimeFormat(deliveryWindow?.[0]?.startTime ?? "")
    );
    setDeliveryWindowEndTime(
      onSetTimeFormat(deliveryWindow?.[0]?.endTime ?? "")
    );
    setTakeAwayWindowStartTime(
      onSetTimeFormat(takeAwayWindow?.[0]?.startTime ?? "")
    );
    setTakeAwayWindowEndTime(
      onSetTimeFormat(takeAwayWindow?.[0]?.endTime ?? "")
    );
  };

  const onSetTimeFormat = (val) => {
    return val
      ? new Date(moment(val, "H:mm").format("YYYY-MM-DD HH:mm:ss"))
      : "";
  };

  const onSetDeliveryTakeAwayWindow = (startTime, endTime) => {
    return startTime && endTime
      ? [
          {
            startTime: moment(startTime).format("H:mm"),
            endTime: moment(endTime).format("H:mm"),
          },
        ]
      : [];
  };

  const onCancel = () => {
    onUpdateSetVariables(restaurantDetail);
  };

  const handlerOnSwitch = (type, value) => {
    if (type === "isDeliveryEnabled") {
      setIsDeliveryEnabledVal(value);
    } else if (type === "isTakeAwayEnabled") {
      setIsTakeAwayEnabledVal(value);
    } else if (type === "busyMode") {
      setBusyModeVal(value);
    } else if (type === "onlineOrder") {
      setOnlineOrderVal(value);
    }
  };

  const handlerOnChange = (type, value) => {
    if (type === "defaultBusyModeTime") {
      setDefaultBusyModeTimeVal(value.replace(/[^0-9.]/g, ""));
    } else if (type === "minimumCollectionOrderAmount") {
      setMinCollectionOrderAmount(value.replace(/[^0-9.]/g, ""));
    } else if (type === "minimumDeliveryOrderAmount") {
      setMinDeliveryOrderAmount(value.replace(/[^0-9.]/g, ""));
    } else if (type === "deliveryRadius") {
      setDeliveryRadiusVal(value.replace(/[^0-9.]/g, ""));
    } else if (type === "deliveryWindowStartTime") {
      setDeliveryWindowStartTime(value ? value : "");
    } else if (type === "deliveryWindowEndTime") {
      setDeliveryWindowEndTime(value ? value : "");
    } else if (type === "takeAwayWindowStartTime") {
      setTakeAwayWindowStartTime(value ? value : "");
    } else if (type === "takeAwayWindowEndTime") {
      setTakeAwayWindowEndTime(value ? value : "");
    }
  };

  const onSetBusyModeTime = (type) => {
    let val = busyModeTimeVal;
    if (type === "+") {
      val += 5;
      setBusyModeTimeVal(val);
    } else {
      val -= 5;
      if (val >= 0) {
        setBusyModeTimeVal(val);
      }
    }
  };

  const ToggleSwitchLabel = ({ label, info }) => {
    return (
      <Typography
        className={styles?.["toggle-label"]}
        sx={{ ...styles1.labelStyle, path: { fill: "#000" } }}
      >
        {label}
        {info ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                  backgroundColor: "#EEE",
                  color: "#000",
                },
              },
            }}
            title={info}
            placement="top"
          >
            <InfoIcon
              height={15}
              width={15}
              style={{ cursor: "pointer", marginLeft: 4 }}
            />
          </Tooltip>
        ) : null}
      </Typography>
    );
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {loading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12} mb={2}>
            <RestaurantHeader
              title={"Take Away Settings"}
              onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={styles?.["main-view"]}
            px={3}
            py={2}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              mt={1}
              mb={2}
            >
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel
                  label={"Online Order"}
                  info={"Toggle option for user to place order online"}
                />
                <SwitchInput
                  checked={onlineOrderVal}
                  onChange={(val) => handlerOnSwitch("onlineOrder", val)}
                />
              </Box>
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel label={"Delivery"} info={""} />
                <SwitchInput
                  checked={isDeliveryEnabledVal}
                  onChange={(val) => handlerOnSwitch("isDeliveryEnabled", val)}
                />
              </Box>
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel label={"TakeAway"} info={""} />
                <SwitchInput
                  checked={isTakeAwayEnabledVal}
                  onChange={(val) => handlerOnSwitch("isTakeAwayEnabled", val)}
                />
              </Box>
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel label={"Busy Mode"} info={""} />
                <SwitchInput
                  checked={busyModeVal}
                  onChange={(val) => handlerOnSwitch("busyMode", val)}
                />
              </Box>
              {busyModeVal ? (
                <Box className={styles?.["switch-input-view"]}>
                  <ToggleSwitchLabel
                    label={"Busy Mode Time (in mins)"}
                    info={""}
                  />
                  <AddSubMenuBtn
                    count={busyModeTimeVal}
                    onAddSub={onSetBusyModeTime}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={defaultBusyModeTimeVal}
                  setValue={(val) =>
                    handlerOnChange("defaultBusyModeTime", val)
                  }
                  placeholder={""}
                  required={true}
                  label={"Default Busy Mode Time"}
                  labelStyle={styles1.labelStyle}
                  variant={"filled"}
                  border={"1px solid #AAA"}
                  error={error?.defaultBusyModeTime}
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={deliveryRadiusVal}
                  setValue={(val) => handlerOnChange("deliveryRadius", val)}
                  placeholder={""}
                  required={true}
                  label={"Delivery Radius"}
                  labelStyle={styles1.labelStyle}
                  variant={"filled"}
                  border={"1px solid #AAA"}
                  error={error?.deliveryRadius}
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={minCollectionOrderAmount}
                  setValue={(val) =>
                    handlerOnChange("minimumCollectionOrderAmount", val)
                  }
                  placeholder={""}
                  label={"Minimum Collection Order Amount"}
                  labelStyle={styles1.labelStyle}
                  required={true}
                  variant={"filled"}
                  border={"1px solid #AAA"}
                  error={error?.minimumCollectionOrderAmount}
                />
              </Grid>

              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={minDeliveryOrderAmount}
                  setValue={(val) =>
                    handlerOnChange("minimumDeliveryOrderAmount", val)
                  }
                  label={"Minimum Delivery Order Amount"}
                  labelStyle={styles1.labelStyle}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  border={"1px solid #AAA"}
                  error={error?.minimumDeliveryOrderAmount}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12} md={6} spacing={2} mb={2}>
                <Grid item xs={12}>
                  <label style={styles1.labelStyle}>Delivery Window</label>
                </Grid>
                <Grid item xs={6}>
                  <FormTimePickers
                    value={deliveryWindowStartTime}
                    format="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("deliveryWindowStartTime", newValue);
                    }}
                    label={"Start Time"}
                    placeholder={"Time"}
                    clearable={true}
                    textFieldSlotProps={styles1.textFieldSlotProps}
                    error={error?.deliveryWindowStartTime}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTimePickers
                    value={deliveryWindowEndTime}
                    format="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("deliveryWindowEndTime", newValue);
                    }}
                    label={"End Time"}
                    placeholder={"Time"}
                    clearable={true}
                    textFieldSlotProps={styles1.textFieldSlotProps}
                    error={error?.deliveryWindowEndTime}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={6} spacing={2} mb={2}>
                <Grid item xs={12}>
                  <label style={styles1.labelStyle}>Take Away Window</label>
                </Grid>
                <Grid item xs={6}>
                  <FormTimePickers
                    value={takeAwayWindowStartTime}
                    format="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("takeAwayWindowStartTime", newValue);
                    }}
                    label={"Start Time"}
                    placeholder={"Time"}
                    clearable={true}
                    textFieldSlotProps={styles1.textFieldSlotProps}
                    error={error?.takeAwayWindowStartTime}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTimePickers
                    value={takeAwayWindowEndTime}
                    format="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("takeAwayWindowEndTime", newValue);
                    }}
                    label={"End Time"}
                    placeholder={"Time"}
                    clearable={true}
                    textFieldSlotProps={styles1.textFieldSlotProps}
                    error={error?.takeAwayWindowEndTime}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent={"flex-end"} mt={2}>
              <Grid item xs={12} md={5}>
                <Box className={styles?.["btn-main-View"]} mt={2}>
                  <Button
                    type="button"
                    className={styles?.["cancel-btn"]}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    disabled={saveBtnDisabled}
                    sx={{
                      opacity: saveBtnDisabled ? 0.2 : 1,
                    }}
                    className={styles?.["save-btn"]}
                    onClick={onSaveGeneralSettings}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default TakeAwaySettings;

const styles1 = {
  labelStyle: {
    fontSize: 14,
    fontFamily: "GilroySemiBold",
  },
  textFieldSlotProps: {
    sx: {
      "&.MuiTextField-root": {
        width: "100%",
      },
      "& .MuiInputBase-root": {
        border: "1px solid #AAA",
        borderRadius: "12px",
        height: 46,
        px: 1,
        mt: 3,
        "&:before, :after, :hover:not(.Mui-disabled):before": {
          border: 0,
        },
        "&.Mui-error:after": {
          border: 0,
        },
      },
      svg: { color: "#000", fontSize: 18 },
      "& .MuiButtonBase-root": {
        width: 35,
        height: 35,
      },
    },
  },
};
